/** @jsx jsx */
import { jsx, Container, Heading, Text } from 'theme-ui'
import { useQuery, gql } from '@apollo/client'
import Layout from '../components/Layout'
import BrandSelector from '../components/BrandSelector'
import Banner from '../components/Banner'
import { useLangState } from '../context/language-context'
import Arrow from '../assets/svg/arrow.svg'

const WP_QUERY = gql`
  query MyQuery {
    pages {
      nodes {
        siteContent {
          portfolio {
            heroImage {
              mediaItemUrl
            }
            heroTitleEnglish
            heroTitleFrench
            heroTextEnglish
            heroTextFrench
          }
        }
      }
    }
    brands(first: 50) {
      edges {
        node {
          id
          title
          brandFields {
            englishText
            frenchText
            location
            link
            imageOne {
              mediaItemUrl
            }
            imageTwo {
              mediaItemUrl
            }
            order
          }
        }
      }
    }
  }
`

export default () => {
  const langState = useLangState()
  const lang = langState ? langState.lang : 'en'
  const { loading, data } = useQuery(WP_QUERY)

  if (loading) return null

  const {
    heroImage,
    heroTitleEnglish,
    heroTitleFrench,
    heroTextEnglish,
    heroTextFrench,
  } = data.pages.nodes[0].siteContent.portfolio

  const brands = data.brands.edges

  return (
    <Layout>
      <section sx={{
        position: 'relative',
        maxHeight: '800px',
        height: '100vh',
        mt: ['-90px', '-90px', '-120px'],
        textAlign: 'center'
      }}>
        {heroImage &&
          <img src={heroImage.mediaItemUrl} alt="hero" sx={{
            height: '100%',
            width: '100%',
            objectFit: 'cover'
          }} />
        }

        <div sx={{
          position: 'absolute',
          top: '60%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: '480px'
        }}>
          <Heading sx={{ mb: 2 }}>
            {lang === 'en' ? heroTitleEnglish : heroTitleFrench}
          </Heading>

          <Text sx={{ px: [4, 4, 0], fontSize: ['16px', '16px', '20px'] }}>
            {lang === 'en' ? heroTextEnglish : heroTextFrench}
          </Text>

          <img src={Arrow} alt="arrow" sx={{ mt: 6 }}/>
        </div>
      </section>

      <Container sx={{ mb: 4 }}>
        <BrandSelector brands={brands} />
      </Container>

      <Banner lang={lang} />
    </Layout>
  )
}

