import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from 'theme-ui'
import theme from './theme'
import graphQlService from './services/graphql'
import * as serviceWorker from './serviceWorker'
import { Router, LocationProvider } from '@reach/router'

import { LangProvider } from './context/language-context'
import { AgeProvider } from './context/age-context'

import FourOhFour from './pages/404'
import Index from './pages/index'
import Portfolio from './pages/portfolio'
import Team from './pages/team'
import Contact from './pages/contact'

import Menu from './components/Menu'
import Purchase from './pages/purchase'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LocationProvider>
        <ApolloProvider client={graphQlService}>
          <AgeProvider>
            <LangProvider>
              <div style={{ height: '100vh', overflowY: 'scroll', scrollBehavior: 'smooth' }}>
                <Menu />

                <Router>
                  <Index path="/" />
                  <FourOhFour path="/404" />
                  <Portfolio path="/portfolio" />
                  <Team path="/team" />
                  <Contact path="/contact" />
                  <Purchase path="/purchase" />
                </Router>
              </div>
            </LangProvider>
          </AgeProvider>
        </ApolloProvider>
      </LocationProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
