/** @jsx jsx */
import { jsx, Container } from 'theme-ui';
import { useQuery, gql } from '@apollo/client';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import { useLangState } from '../context/language-context';
import { ProvinceMap } from '../components/ProvinceMap';
import { useState } from 'react';
import { MapLink } from '../styles/map.style';

const WP_QUERY = gql`
  query MyQuery {
    pages {
      nodes {
        siteContent {
          purchase {
            on
            qc
            bc
            mb
            ns
            at
            ab
            sk
          }
        }
      }
    }
  }
`;

export default () => {
  const langState = useLangState();
  const lang = langState ? langState.lang : 'en';
  const { loading, data } = useQuery(WP_QUERY);
  const [selected, setSelected] = useState();

  if (loading) return null;

  const linksByArea = data?.pages?.nodes?.[0].siteContent?.purchase || {};
  const currentLinks = selected
    ? linksByArea[selected.toLowerCase()]?.split('\n')
    : [];

  const onMapClick = area => {
    const currentLinks = linksByArea[area.toLowerCase()]?.split('\n');
    if (currentLinks?.length === 1 && currentLinks[0]) {
      window.open(currentLinks[0], '_blank');
    } else {
      setSelected(area);
    }
  };

  return (
    <Layout>
      <section
        sx={{
          position: 'relative',
          maxHeight: '800px',
          height: '100vh',
          mt: ['-90px', '-90px', '-120px'],
          textAlign: 'center',
        }}
      >
        <ProvinceMap onClick={onMapClick} selected={selected} />
      </section>

      <Container sx={{ mt: 2, mb: 4, width: '60%' }}>
        {currentLinks.length && currentLinks[0] ? (
          <ol>
            {currentLinks.map(item => {
              const [name, url] = item.split(',');
              if (!url)
                return (
                  <li>
                    <strong>{selected}:</strong>{' '}
                    <MapLink href={name} target="_blank">
                      {name}
                    </MapLink>
                  </li>
                );
              return (
                <li>
                  <strong>{name}:</strong>{' '}
                  <MapLink href={url} target="_blank">
                    {url}
                  </MapLink>
                </li>
              );
            })}
          </ol>
        ) : selected ? (
          'Coming soon!'
        ) : (
          ''
        )}
      </Container>

      <Banner lang={lang} />
    </Layout>
  );
};
