/** @jsx jsx */
import { jsx, Button } from 'theme-ui'
import { Link as ReachLink } from '@reach/router'
import Slider from 'react-slick'
import Next from '../assets/svg/next.svg'
import Prev from '../assets/svg/prev.svg'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default ({ carouselWines, lang }) => {
  const arrowStyles = {
    maxWidth: ['50px', '50px', '85px', '85px'],
    maxHeight: ['50px', '50px', '85px', '85px'],
    fontSize: 0,
    lineHeight: 0,
    position: 'absolute',
    top: ['25%', '25%', '50%'],
    transform: ['translate(-40px, -50%)', 'translate(0, -50%)'],
    width: '100px',
    height: '100px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    zIndex: 11,
    '&:focus': {
      outline: 'none'
    },
    '&:before': {
      content: 'none'
    }
  }

  const PrevArrow = ({ onClick, className }) => (
    <button
      onClick={onClick}
      aria-label="previous"
      sx={arrowStyles}
      className={className}
      style={{ left: '25%' }}
    >
      <img src={Prev} alt="previous" />
    </button>
  )

  const NextArrow = ({ onClick, className }) => (
    <button
      onClick={onClick}
      aria-label="next"
      sx={arrowStyles}
      className={className}
      style={{ right: '25%' }}
    >
      <img src={Next} alt="next" />
    </button>
  )

  const settings = {
    infinite: true,
    centerMode: true,
    slidesToShow: 3,
    speed: 750,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          centerMode: false,
          slidesToShow: 2
        }
      }
    ]
  }

  return (
    <div sx={{
      width: '100%',
      height: '100vh',
      maxHeight: '700px',
      mt: 6,
      mb: 5,
      pt: [4, 0],
      '.slick-list': {
        maxHeight: '700px'
      },
      '.slick-track': {
        height: '700px',
        border: '1px solid transparent'
      },
      '.slick-prev': {
        display: ['none', 'none', 'block']
      }
    }}>
      <Slider {...settings}>
        {carouselWines.map(wine => {
          const { id, name, description, image } = wine

          return (
            <div key={id} sx={{
              position: 'relative',
              marginTop: ['60px', '110px'],
              opacity: .5,
              transition: 'opacity, transform',
              transitionDuration: '.750s',
              transitionTimingFunction: 'ease-in',
              zIndex: 1,
              '&:focus': {
                outline: 'none'
              },
              '.slick-current &': {
                opacity: 1,
                transform: 'scale(1.35)',

                '.wine-info': { opacity: 1 }
              }
            }}>
              <div className="wine-info" sx={{
                position: 'absolute',
                top: ['220px', 2],
                right: [-5, '-50px'],
                minWidth: '150px',
                width: '200px',
                opacity: 0,
                transition: 'opacity, .750s ease-in-out',
                transitionDelay: '.5s',
                zIndex: '2',
              }}>
                <h3 sx={{ m: 0, fontSize: '15px' }}>{name}</h3>

                <p sx={{ my: 2, fontSize: ['11px', '11px', '12px'] }}>{description}</p>

                <Button as={ReachLink} to="/portfolio" sx={{
                  mt: 1,
                  py: [1, 1, 1],
                  px: 3,
                  fontSize: '12px',
                  fontWeight: '400',
                  letterSpacing: '1.25px'
                }}>
                    {lang === 'en' ? 'Learn More' : 'En Savoir Plus'}
                  </Button>
              </div>

              <img
                src={image}
                className="wine-image"
                alt={name}
                sx={{ width: '100%' }}/>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}