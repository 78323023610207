/** @jsx jsx */
import { useState, useEffect, useRef } from 'react'
import { jsx } from 'theme-ui'
import { Container, Box, Button } from 'theme-ui'
import { ageContext } from '../context/age-context'
import Logo from '../assets/svg/logo.svg'
import { gql, useQuery } from '@apollo/client'

const WP_QUERY = gql`
  query MyQuery {
    pages {
      nodes {
        siteContent {
          ageWall {
            ageWallButtonText
            ageWallText
            backgroundImage {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`

export default () => {
  const { loading, data } = useQuery(WP_QUERY)
  const submitBtnRef = useRef(null)
  const boxRef = useRef(null)

  console.warn('loading: ', loading);
  console.warn('data: ', data);

  useEffect(() => {
    setTimeout(() => {
      boxRef.current.style.opacity = 1
    }, 1000)
  }, [])

  if (loading) return null

  const {
    backgroundImage,
    ageWallText,
    ageWallButtonText,
  } = data.pages.nodes[0].siteContent.ageWall

  const validateAge = (context, e) => {
    e.preventDefault()

    context.setAge()
  }

  return (
    <Container sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100vh'
    }}>
      <div sx={{
        position: 'absolute',
        width: '100%',
        top: '0',
        zIndex: '-1'
      }}>

        <img src={backgroundImage.mediaItemUrl} alt="background" sx={{
          width: '100%',
          height: '100vh',
          objectFit: 'cover',
          opacity: .5
         }} />
      </div>
      <Box ref={boxRef} sx={{
        px: [2, 4, 6],
        py: [2, 4],
        textAlign: 'center',
        background: 'rgba(0,0,0,.5)',
        borderRadius: '35px',
        transition: 'opacity 1.25s ease-in-out',
        opacity: 0,
      }}>
        <img src={Logo} alt="logo" sx={{ width: ['200px', '180px', '310px'], mx: 'auto'}}/>

        <p sx={{ mb: '30px', color: 'white'}}>
          {ageWallText}
        </p>

        <ageContext.Consumer>
          {context => (
            <form onSubmit={e => validateAge(context, e)} sx={{
              maxWidth: '540px',
              py: 2,
              px: 4,
              'label': {
                display: 'block',
                mt: 2,
                mb: [2, 0],
                textAlign: 'center'
              }
            }}>
              <Button type="submit" ref={submitBtnRef} sx={{
                minWidth: '150px',
                border: '2px solid',
                borderColor: 'primary',
                borderRadius: '35px',
                letterSpacing: 'body',
                color: 'primary',
                fontSize: '22px',
                cursor: 'pointer',
                transition: 'opacity .5s ease-in-out, border-color .25s ease-in-out',
                '&:hover': {
                  borderColor: 'primary'
                },
                '&:focus': {
                  outline: 'none',
                  borderColor: 'primary'
                },
                ':disabled': {
                  cursor: 'default',
                  opacity: .35,
                  border: '2px solid transparent',
                }
              }}>
                {ageWallButtonText}
              </Button>
            </form>
          )}
        </ageContext.Consumer>
      </Box>
    </Container>
  )
}