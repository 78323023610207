/** @jsx jsx */
import { jsx, Box, Text, Button, Flex } from 'theme-ui';
import { useQuery, gql } from '@apollo/client';
import { SiteInput } from '../styles/input.style';
import { BannerForm } from '../styles/form.style';
import { useEffect } from 'react';

const WP_QUERY = gql`
  query MyQuery {
    pages {
      nodes {
        siteContent {
          joinCommunity {
            joinButtonTextEnglish
            joinButtonTextFrench
            joinTitleEnglish
            joinTitleFrench
            joinDescriptionEnglish
            joinDescriptionFrench
          }
        }
      }
    }
  }
`;

export default ({ lang }) => {
  useEffect(() =>{
    setTimeout(() => {
      window.vin65remote.cart.init('https://shop.principlefinewines.com',0);
      window.vin65remote.form.form('https://shop.principlefinewines.com','Join our Community');
    }, 2000);
  }, []);

  const { loading, data } = useQuery(WP_QUERY);

  if (loading) return null;

  const {
    joinButtonTextEnglish,
    joinButtonTextFrench,
    joinTitleEnglish,
    joinTitleFrench,
    joinDescriptionEnglish,
    joinDescriptionFrench,
  } = data.pages.nodes[0].siteContent.joinCommunity;

  return (
    <Box
      sx={{
        py: 4,
        backgroundColor: 'panel',
        textAlign: 'center',
        color: 'secondary',
      }}
    >
      {/* <form
        method="post"
        action="https://shop.principlefinewines.com/index.cfm?method=pformV2.processForm"
      > */}
        {/* <input
          type="hidden"
          name="FormID"
          value="0B5302CB-B7CF-B866-2352-6F9346B650B5"
        />
        <input
          type="hidden"
          name="FormDivID"
          value="Form0B5302CB-B7CF-B866-2352-6F9346B650B5"
        />
        <input type="hidden" name="isSingleOptIn" value="1" /> */}
        <h3 sx={{ px: 2 }}>
          {lang === 'en' ? joinTitleEnglish : joinTitleFrench}
        </h3>

        <Flex
          sx={{
            flexDirection: ['column', 'column', 'row'],
            width: ['90%', '70%', '70%'],
            margin: 'auto',
            textAlign: 'left',
          }}
        >
          <Box sx={{ width: ['100%', '80%', '40%'] }}>
            <p>
              <Text sx={{ px: 2 }}>
                {lang === 'en' ? joinDescriptionEnglish : joinDescriptionFrench}
              </Text>
            </p>
          </Box>
          <Box sx={{ width: ['100%', '80%', '60%'] }}>
            <Flex
              sx={{
                flexDirection: ['column', 'row', 'row'],
                justifyContent: 'space-around',
              }}
            >
              <BannerForm sx={{ width: ['100%'], padding: '0 1em' }}><div v65remotejs="form">&nbsp;</div></BannerForm>

              {/* <Box sx={{ width: ['100%', '80%', '50%'], padding: '0 1em' }}>
                <p>
                  <label for="FirstName">*First Name</label><br/>
                  <SiteInput
                    type="text"
                    name="FirstName"
                    id="FirstName"
                    aria-required="true"
                    aria-describedby="InvalidFirstName"
                  />
                </p>

                <p>
                  <label for="LastName">*Last Name</label><br/>
                  <SiteInput
                    type="text"
                    name="LastName"
                    id="LastName"
                    aria-required="true"
                    aria-describedby="InvalidLastName"
                  />
                </p>
              </Box>
              <Box sx={{ width: ['100%', '80%', '50%'], padding: '0 1em' }}>
                <p>
                  <label for="Email">*Email</label><br/>
                  <SiteInput
                    type="text"
                    name="Email"
                    id="Email"
                    aria-required="true"
                    aria-describedby="InvalidEmailFormat"
                  />
                </p>
                <p>
                  <Button
                    type="submit"
                    sx={{
                      mt: 3,
                      borderColor: 'secondary',
                      color: 'secondary',
                      '&:hover': {
                        backgroundColor: 'secondary',
                        color: 'white',
                      },
                    }}
                  >
                    {lang === 'en'
                      ? joinButtonTextEnglish
                      : joinButtonTextFrench}
                  </Button>
                </p>
              </Box> */}
            </Flex>
          </Box>
        </Flex>
      {/* </form> */}
    </Box>
  );
};
