/** @jsx jsx */
import { jsx, Link } from 'theme-ui'
import { Link as ReachLink } from '@reach/router'
import { useLangState } from '../context/language-context'
import { PlainLink } from './Menu'

export default ({ navItems, showMenu, setShowMenu }) => {
  const langState = useLangState()
  const lang = langState ? langState.lang : 'en'
  const tabIndex = showMenu ? 0 : -1

  return (
    <nav aria-hidden={!showMenu} sx={{
      position: 'absolute',
      display: showMenu ? 'flex' : 'none',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      top: '0',
      left: '0',
      right: '0',
      height: '100vh',
      background: 'black',
      zIndex: 10,
      'a': {
        display: 'inline-block',
        paddingTop: '1rem',
        paddingbottom: '1rem',
        fontSize: '1.1rem',
        fontWeight: '900',
        letterSpacing: '.15em',
        textTransform: 'uppercase',
        color: 'primary',
        textDecoration: 'none',
        '&:focus': {
          outline: 'none',
          color: 'primary'
        }
      }
    }}>
      {navItems.map(navItem => (
        <Link key={navItem.labelEnglish} as={navItem.target || navItem.link.includes('https:') ? PlainLink : ReachLink} to={navItem.link} target={navItem.target ? navItem.target : undefined} variant="nav">
          {lang === 'en' ? navItem.labelEnglish : navItem.labelFrench}
        </Link>
      ))}
      {/* <Link as={ReachLink} to="/" variant="nav" tabIndex={tabIndex} onClick={() => setShowMenu(!showMenu)}>
        {lang === 'en' ? 'Our Story' : 'Notre Histoire'}
      </Link>
      <Link as={ReachLink} to="/portfolio" variant="nav" tabIndex={tabIndex} onClick={() => setShowMenu(!showMenu)}>
        {lang === 'en' ? 'Portfolio' : 'Portefeuille'}
      </Link>
      <Link sx={{ pointerEvents: 'none' }} as={ReachLink} to="/" variant="nav" tabIndex={tabIndex} onClick={() => setShowMenu(!showMenu)}>
        {lang === 'en' ? 'Purchase' : 'Acheter'}
      </Link>
      <Link as={ReachLink} to="/team" variant="nav" tabIndex={tabIndex} onClick={() => setShowMenu(!showMenu)}>
        {lang === 'en' ? 'Our Team' : 'Notre Équipe'}
      </Link>
      <Link as={ReachLink} to="/contact" variant="nav" onClick={() => setShowMenu(!showMenu)}>
        {lang === 'en' ? 'Connect' : 'Relier'}
      </Link> */}
    </nav>
  )
}