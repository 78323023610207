import styled from 'styled-components';

export const SiteInput = styled.input`
  padding: 0 1em;
  width: 90%;
  background: none;
  border: solid 1px #ffffff;
  border-radius: 15px;
  line-height: 2em;
  color: #ffffff;
`;