import styled from 'styled-components';

export const MapWrapper = styled.svg`
  margin-top: 5%;
  stroke: #9F7C4A;

  path:hover {
    fill: #9F7C4A;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  .active {
    fill: #9F7C4A;
    :hover {
      opacity: 0.8;
    }
  }
`;

export const MapLink = styled.a`
  color: #ffffff;
  text-decoration: none;
  :hover {
    opacity: 0.5;
  }
`;