/** @jsx jsx */
import React, { useEffect, useRef } from 'react'
import { jsx, Container } from 'theme-ui'
import { Global } from "@emotion/core"
import Footer from './Footer'
import AgeWall from './AgeWall'
import { useAgeState } from '../context/age-context'
import '../assets/fonts/FuturaPT.css'

export default ({ children, loading }) => {
  const ageState = useAgeState()
  const validAge = ageState ? ageState.validAge : false
  const mainRef = useRef(null)

  useEffect(() => {
    if (validAge) mainRef.current.style.opacity = 1
  }, [validAge])

  return validAge ? (
    <React.Fragment>
      <Global
        styles={() => ({
          "*": {
            boxSizing: `inherit`,
          },
          "::selection": {
            backgroundColor: `secondary`,
            color: `primary`,
          },
          "html" :{
            scrollBehavior: 'smooth'
          },
          "#___gatsby": {
            position: `relative`,
            overflowX: `hidden`,
          },
          "img": {
            maxWidth: '100%'
          }
        })}
      />

      <main
        ref={mainRef}
        role="main"
        sx={{
          opacity: 0,
          transition: 'opacity .75s ease-in',
          transitionDelay: '.5s',
          zIndex: 1,
          scrollBehavior: 'smooth'
        }}
      >
        {children}
      </main>

      <Footer />
    </React.Fragment>
  ) : (
    <Container>
      <AgeWall />
    </Container>
  )
}
