/** @jsx jsx */
import { useState } from 'react'
import { jsx, Link, Flex } from 'theme-ui'
import { Link as ReachLink, useLocation } from '@reach/router'
import Logo from '../assets/svg/logo_white.svg'
import MobileMenu from './MobileMenu'
import Burger from './Burger'
import { useLangDispatch, useLangState } from '../context/language-context'
import { useAgeState } from '../context/age-context'
import { useQuery, gql } from '@apollo/client'

const WP_QUERY = gql`
  query MyQuery {
    navItems {
      nodes {
        id
        title
        navigationFields {
          order
          labelEnglish
          labelFrench
          link
          target
        }
      }
    }
  }
`

export const PlainLink = (props) => (<a {...props} href={props.to}>{props.children}</a>);

const LangToggle = () => {
  const dispatch = useLangDispatch()
  const langState = useLangState()
  const lang = langState ? langState.lang : 'en'

  return (
    <Flex
      as="button"
      onClick={() => dispatch({ type: lang === 'en' ? 'french' : 'english' })}
      sx={{
        position: 'fixed',
        left: '2em',
        ml: '20px',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        color: 'primary',
        fontWeight: 'heading',
        letterSpacing: 'heading',
        fontSize: 1,
        '&:focus': {
          outline: 'none',
        },
        '&:hover': {
          color: 'secondary'
        }
      }}
    >
      <svg width="18" height="18" sx={{ mr: '6px' }}><path d="M9 0C4.037 0 0 4.037 0 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9zm-.643 1.446v2.625H6.402c.093-.245.189-.485.294-.703.495-1.015 1.082-1.648 1.661-1.922zm1.286 0c.579.274 1.166.907 1.66 1.922.106.218.202.458.295.703H9.643V1.446zm-3.55.409a7.82 7.82 0 00-.548.95c-.191.392-.358.817-.51 1.266H3.068a7.696 7.696 0 013.027-2.216zm5.813 0a7.696 7.696 0 013.027 2.216h-1.969c-.15-.45-.318-.874-.509-1.265a7.82 7.82 0 00-.549-.951zm-9.71 3.502h2.478c-.21.93-.337 1.94-.375 3H1.313a7.667 7.667 0 01.883-3zm3.804 0h2.357v3H5.585c.042-1.079.19-2.093.415-3zm3.643 0H12c.225.907.373 1.921.415 3H9.643v-3zm3.683 0h2.478c.485.907.795 1.92.883 3h-2.986a16.345 16.345 0 00-.375-3zM1.313 9.643h2.986c.037 1.062.165 2.07.375 3H2.196a7.68 7.68 0 01-.883-3zm4.272 0h2.772v3H6a14.964 14.964 0 01-.415-3zm4.058 0h2.772a14.964 14.964 0 01-.415 3H9.643v-3zm4.058 0h2.986a7.68 7.68 0 01-.883 3h-2.478c.21-.93.338-1.938.375-3zM3.06 13.929h1.975c.15.448.318.868.509 1.259.167.343.354.667.555.964a7.696 7.696 0 01-3.04-2.223zm3.34 0h1.956v2.631c-.579-.275-1.166-.92-1.66-1.935a8.616 8.616 0 01-.295-.696zm3.242 0h1.955c-.093.245-.189.479-.294.696-.495 1.016-1.082 1.66-1.661 1.935V13.93zm3.321 0h1.976a7.696 7.696 0 01-3.04 2.223c.2-.297.388-.62.555-.964.19-.391.358-.81.51-1.26z" fill="currentColor" fillRule="nonzero"/></svg>

      {lang === 'en' ? 'FR' : 'EN'}
    </Flex>
  )
}

export default () => {
  const [showMenu, setShowMenu] = useState(false)
  const ageState = useAgeState()
  const langState = useLangState()
  const validAge = ageState ? ageState.validAge : false
  const lang = langState ? langState.lang : 'en'
  const location = useLocation()
  const { loading, data } = useQuery(WP_QUERY)

  if (loading) return null

  if (!validAge) return null

  let navItems = []
  try {
    navItems = data.navItems.nodes.map(node => node.navigationFields)
      .sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
  } catch(ex) {
    console.warn('Cant load nav items', data)
  }

  const leftNav = navItems.slice(0, 3)
  const rightNav = navItems.slice(3, navItems.length)

  return (
    <Flex
      as="nav"
      aria-label="Horizontal"
      role="navigation"
      sx={{
        position: ['sticky', 'sticky', 'sticky', 'relative'],
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '85%',
        maxWidth: '1200px',
        height: ['90px', '90px', '120px'],
        mx: 'auto',
        zIndex: 10,
        'a': {
          display: ['none', 'none', 'none', 'inline-block'],
        },
        '.logo': {
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          left: '50%',
          transform: 'translateX(-50%)'
        }
      }}
    >
      {leftNav.map(navItem => (
        <Link key={navItem.labelEnglish} as={navItem.target || navItem.link.includes('https:') ? PlainLink : ReachLink} to={navItem.link} target={navItem.target ? navItem.target : undefined} variant="nav">
          {lang === 'en' ? navItem.labelEnglish : navItem.labelFrench}
        </Link>
      ))}

      <Link className="logo" as={ReachLink} to="/">
        <img
          src={Logo}
          alt="logo"
          sx={{
            width: ['120px', '160px', '160px']
          }}
        />
      </Link>

      <Link variant="nav" style={{ width: '240px' }}></Link>

      {rightNav.map(navItem => (
        <Link key={navItem.labelEnglish} as={navItem.target || navItem.link.includes('https:') ? PlainLink : ReachLink} to={navItem.link} target={navItem.target ? navItem.target : undefined} variant="nav">
          {lang === 'en' ? navItem.labelEnglish : navItem.labelFrench}
        </Link>
      ))}

      <LangToggle />

      <Burger showMenu={showMenu} setShowMenu={setShowMenu} />

      <MobileMenu navItems={navItems} showMenu={showMenu} setShowMenu={setShowMenu} />
    </Flex>
  )
}

