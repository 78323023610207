/** @jsx jsx */
import { useState, useRef, useEffect } from 'react'
import { jsx, Flex, Box, Heading, Text, Button, Select } from 'theme-ui'
import { useLangState } from '../context/language-context'

const FILTER_MAP = {
  Vqa: brand => brand.node.brandFields.location === 'vqa',
  Imports: brand => brand.node.brandFields.location === 'imports'
}

export default ({ brands }) => {
  const langState = useLangState()
  const lang = langState ? langState.lang : 'en'
  const descRef = useRef(null)

  const getFirstBrand = location => {
    const locationBrands = brands
      .filter(FILTER_MAP[location])
      .sort((a, b) => a.node.brandFields.order - b.node.brandFields.order)

    return locationBrands[0].node
  }

  const [filter, setFilter] = useState('Imports')
  const [brand, setBrand] = useState(getFirstBrand(filter))

  const text = lang === 'en'
    ? brand.brandFields.englishText
    : brand.brandFields.frenchText

  const brandList = brands
    .filter(FILTER_MAP[filter])
    .sort((a, b) => a.node.brandFields.order - b.node.brandFields.order)
    .map(wineBrand => {
      const { id, title } = wineBrand.node

      return (
        <Heading
          key={id}
          onClick={() => setBrand(wineBrand.node)}
          dangerouslySetInnerHTML={{__html: title}}
          sx={{
            width: id === brand.id ? '320px' : '280px',
            marginTop: 3,
            padding: '1.25rem 1rem',
            background: '#131415',
            fontSize: id === brand.id ? '16px' : '15px',
            transition: 'width .2s ease-in-out, font-size .2s ease-in-out',
            cursor: 'pointer',
            '&:first-of-type': {
              marginTop: 0
            }
          }}
        />
      )
    }
  )

  const buttonStyles = {
    flex: [1, 1, 'none'],
    minWidth: [null, '200px'],
    px: [null, 4],
    py: 3,
    textTransform: 'uppercase',
    letterSpacing: '.1em',
    fontWeight: 400,
    fontSize: ['12px', '14px'],
    border: '1px solid #b48e59',
    cursor: 'pointer',
    '&:focus': {
      border: '1px solid #b48e59',
      outline: 'none'
    }
  }

  useEffect(() => {
    descRef.current.innerHTML = lang === 'en'
      ? brand.brandFields.englishText
      : brand.brandFields.frenchText
  }, [lang, brand])

  const handleChange = id => {
    const selectedBrand = brands.find(brand => brand.node.id === id)
    setBrand(selectedBrand.node)
  }

  const changeLocation = location => {
    setFilter(location)
    setBrand(getFirstBrand(location))
  }

  return (
    <section
      sx={{
        minHeight: [0, '600px'],
        transition: 'opacity .5s ease-in-out .5s'
      }}
    >
      <Flex sx={{ mx: [4, 4, 0] }}>
        <button
          onClick={() => changeLocation('Imports')}
          sx={{
            background: filter === 'Imports' ? '#b48e59' : 'black',
            color: filter === 'Imports' ? 'white' : 'primary',
            ...buttonStyles
          }}
        >
          International
        </button>
        <button
          onClick={() => changeLocation('Vqa')}
          sx={{
            background: filter === 'Imports' ? 'black' : '#b48e59',
            color: filter === 'Imports' ? 'primary' : 'white',
            ...buttonStyles
          }}
        >
          Canadian
        </button>
      </Flex>

      <Flex sx={{
        flexDirection: ['column', 'column', 'row'],
        justifyContent: 'space-between',
        marginTop: [4, 4, '60px'],
      }}>
        <Box sx={{
          display: ['none', 'none', 'block'],
          width: '280px'
         }}>
          {brandList}
        </Box>

        <Box sx={{
          display: ['block', 'block', 'none'],
          mb: 4,
          mx: 4
         }}>
          <Select onChange={e => handleChange(e.target.value)} sx={{
            border: '1px solid',
            borderColor: 'primary',
            '&:focus': {
              border: '1px solid'
            }
           }}>
            {brands.filter(FILTER_MAP[filter]).map(({ node }) =>
              <option key={node.id} value={node.id} dangerouslySetInnerHTML={{__html: node.title}} />
            )}
          </Select>
        </Box>

        <Box sx={{ flex: 1, marginLeft: [0, 0, 6] }}>
          <Heading dangerouslySetInnerHTML={{__html: brand.title}} sx={{
            color: 'white',
            fontSize: '22px',
            px: [4, 4, 0]
          }} />

          <Text ref={descRef} sx={{
            maxWidth: '880px',
            mt: 3,
            mb: 4,
            px: 4,
            pl: [4, 4, 0],
            fontSize: '18px'
          }}>
            {text}
          </Text>

          <Button
            as="a"
            href={brand.brandFields.link}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ mx: [4, 4, 0] }}
          >
            {lang === 'en' ? 'Visit Our Website' : 'Visitez Notre Site Web'}
          </Button>

          <Flex sx={{
            flexDirection: ['column', 'column', 'column', 'row'],
            maxWidth: '880px',
            height: ['auto', 'auto', 'auto', '400px'],
            mt: 4,
            pr: 4,
            overflow: 'hidden',
          }}>
            <Box sx={{
              position: 'relative',
              flex: 1.75,
              height: ['500px', '500px', 'auto', '100%'],
              backgroundColor: 'panel'
            }}>
              {brand.brandFields.imageOne &&
                <img src={brand.brandFields.imageOne.mediaItemUrl} alt="brand one" sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}/>
              }
            </Box>

            <Box sx={{ flex: 1 }}>
              {brand.brandFields.imageTwo &&
                <img src={brand.brandFields.imageTwo.mediaItemUrl} alt="brand two" sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}/>
              }
            </Box>
          </Flex>
        </Box>
      </Flex>
    </section>
  )
}