import { ApolloClient, InMemoryCache } from '@apollo/client';

const graphQlService = new ApolloClient({
  uri: process.env.REACT_APP_WPGRAPHQL_URL,
  cache: new InMemoryCache({
    addTypename: false
  })
});

export default graphQlService;
