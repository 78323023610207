/** @jsx jsx */
import { jsx, Container } from 'theme-ui'
import { useQuery, gql } from '@apollo/client'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'
import Banner from '../components/Banner'
import { useLangState } from '../context/language-context'

const WP_QUERY = gql`
  query MyQuery {
    pages {
      nodes {
        siteContent {
          contact {
            heroImage {
              mediaItemUrl
            }
            heroTitleEnglish
            heroTitleFrench
            namePlaceholderEnglish
            namePlaceholderFrench
            emailPlaceholderEnglish
            emailPlaceholderFrench
            provincePlaceholderEnglish
            provincePlaceholderFrench
            phonePlaceholderEnglish
            phonePlaceholderFrench
            backgroundPlaceholderEnglish
            backgroundPlaceholderFrench
            messagePlaceholderEnglish
            messagePlaceholderFrench
            buttonTextEnglish
            buttonTextFrench
          }
        }
      }
    }
  }
`

export default () => {
  const langState = useLangState()
  const lang = langState ? langState.lang : 'en'
  const { loading, data } = useQuery(WP_QUERY)

  if (loading) return null

  const {
    heroImage,
    namePlaceholderEnglish,
    namePlaceholderFrench,
    emailPlaceholderEnglish,
    emailPlaceholderFrench,
    provincePlaceholderEnglish,
    provincePlaceholderFrench,
    phonePlaceholderEnglish,
    phonePlaceholderFrench,
    backgroundPlaceholderEnglish,
    backgroundPlaceholderFrench,
    messagePlaceholderEnglish,
    messagePlaceholderFrench,
    buttonTextEnglish,
    buttonTextFrench
  } = data.pages.nodes[0].siteContent.contact

  const englishPlaceholders = {
    name: namePlaceholderEnglish,
    email: emailPlaceholderEnglish,
    province: provincePlaceholderEnglish,
    phone: phonePlaceholderEnglish,
    background: backgroundPlaceholderEnglish,
    message: messagePlaceholderEnglish,
    buttonText: buttonTextEnglish,
    successText: 'Message sent successfully!',
    errorText: 'Something went wrong. Please try again.'
  }

  const frenchPlaceholders = {
    name: namePlaceholderFrench,
    email: emailPlaceholderFrench,
    province: provincePlaceholderFrench,
    phone: phonePlaceholderFrench,
    background: backgroundPlaceholderFrench,
    message: messagePlaceholderFrench,
    buttonText: buttonTextFrench,
    successText: 'Message envoyé avec succès!',
    errorText: 'Un problème est survenu. Veuillez réessayer.'
  }

  return (
    <Layout>
      <section sx={{
        position: 'relative',
        maxHeight: '360px',
        height: '100vh',
        mt: ['-90px', '-90px', '-120px'],
        textAlign: 'center'
      }}>
        {heroImage &&
          <img src={heroImage.mediaItemUrl} alt="hero" sx={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'bottom'
          }} />
        }
      </section>

      <Container sx={{ position: 'relative', mt: ['-4rem', '-6.5rem'] }}>
        <ContactForm
          placeholders={lang === 'en' ? englishPlaceholders : frenchPlaceholders}
          lang={lang}
        />
      </Container>

      <Banner lang={lang} />
    </Layout>
  )
}
