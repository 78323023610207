/** @jsx jsx */
import { jsx, Heading, Text } from 'theme-ui'
import { useQuery, gql } from '@apollo/client'
import Layout from '../components/Layout'
import TeamMembers from '../components/TeamMembers'
import Banner from '../components/Banner'
import { useLangState } from '../context/language-context'
import Arrow from '../assets/svg/arrow.svg'

const WP_QUERY = gql`
  query MyQuery {
    pages {
      nodes {
        siteContent {
          team {
            heroImage {
              mediaItemUrl
            }
            heroTitleEnglish
            heroTitleFrench
            heroTextEnglish
            heroTextFrench
          }
        }
      }
    }
    teamMembers {
      edges {
        node {
          id
          title
          profileFields {
            bioEnglish
            bioFrench
            roleEnglish
            roleFrench
            order
            image {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`

export default () => {
  const langState = useLangState()
  const lang = langState ? langState.lang : 'en'
  const { loading, data } = useQuery(WP_QUERY)

  if (loading) return null

  const {
    heroImage,
    heroTitleEnglish,
    heroTitleFrench,
    heroTextEnglish,
    heroTextFrench,
  } = data.pages.nodes[0].siteContent.team

  const teamMembers = data.teamMembers.edges

  const teamMembersEnglish = teamMembers.map(member => (
    {
      id: member.node.id,
      name: member.node.title,
      order: member.node.order,
      role: member.node.profileFields.roleEnglish,
      bio: member.node.profileFields.bioEnglish,
      image: member.node.profileFields.image.mediaItemUrl
    }
  ))

  const teamMembersFrench = teamMembers.map(member => (
    {
      id: member.node.id,
      name: member.node.title,
      order: member.node.order,
      role: member.node.profileFields.roleFrench,
      bio: member.node.profileFields.bioFrench,
      image: member.node.profileFields.image.mediaItemUrl
    }
  ))

  return (
    <Layout>
      <section sx={{
        position: 'relative',
        maxHeight: '800px',
        height: '100vh',
        mt: ['-90px', '-90px', '-120px'],
        textAlign: 'center'
      }}>
        {heroImage &&
          <img src={heroImage.mediaItemUrl} alt="hero" sx={{
            height: '100%',
            width: '100%',
            objectFit: 'cover'
          }} />
        }

        <div sx={{
          position: 'absolute',
          top: '60%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: '480px'
        }}>
          <Heading sx={{ mb: 2 }}>
            {lang === 'en' ? heroTitleEnglish : heroTitleFrench}
          </Heading>

          <Text sx={{ px: [4, 4, 0], fontSize: ['16px', '16px', '20px'] }}>
            {lang === 'en' ? heroTextEnglish : heroTextFrench}
          </Text>

          <img src={Arrow} alt="arrow" sx={{ mt: 6 }}/>
        </div>
      </section>

      <TeamMembers teamMembersData={lang === 'en' ? teamMembersEnglish : teamMembersFrench} />

      <Banner lang={lang} />
    </Layout>
  )
}