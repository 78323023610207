/** @jsx jsx */
import { jsx, Flex, Link } from 'theme-ui'
import React from 'react'
import { Link as ReachLink } from '@reach/router'
import { useLocation } from '@reach/router'
import { useQuery, gql } from '@apollo/client'

import { useLangState } from '../context/language-context'
import Instagram from '../assets/svg/instagram.svg'
import Facebook from '../assets/svg/facebook.svg'
import { PlainLink } from './Menu'

const WP_QUERY = gql`
  query MyQuery {
    pages {
      nodes {
        siteContent {
          footer {
            copyrightEnglish
            copyrightFrench
            instagramLink
            facebookLink
          }
        }
      }
    }
    footerNavItems {
      nodes {
        id
        title
        navigationFields {
          order
          labelEnglish
          labelFrench
          link
          target
        }
      }
    }
  }
`

export default () => {
  const langState = useLangState()
  const lang = langState ? langState.lang : 'en'
  const location = useLocation()
  const { loading, data } = useQuery(WP_QUERY)

  if (loading) return null

  const {
    copyrightEnglish,
    copyrightFrench,
    instagramLink,
    facebookLink
  } = data.pages.nodes[0].siteContent.footer

  let navItems = []
  try {
    navItems = data.footerNavItems.nodes.map(node => node.navigationFields)
      .sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
  } catch(ex) {
    console.warn('Cant load nav items', data)
  }

  return (
    <Flex
      as="footer"
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        mt: [0, 0, 0, 4],
        py: [4, 4, 4, 0],
        maxWidth: '1200px',
        minHeight: '260px',
        mx: 'auto'
      }}
    >
      <Flex sx={{
        flexDirection: ['column', 'column', 'column', 'row'],
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '> * + *': {
          mt: [4, 4, 4, 0]
        }
      }}>
        {navItems.map(navItem => (
          <Link key={navItem.labelEnglish} as={navItem.target || navItem.link.includes('https:') ? PlainLink : ReachLink} to={navItem.link} target={navItem.target ? navItem.target : undefined} variant="nav">
            {lang === 'en' ? navItem.labelEnglish : navItem.labelFrench}
          </Link>
        ))}
        {/* {location.pathname !== '/' ? (
          <React.Fragment>
            <Link as={ReachLink} to="/" variant="nav">
              {lang === 'en' ? 'Home' : 'Home'}
            </Link>
            <Link as={ReachLink} to="/" variant="nav">
              {lang === 'en' ? 'Our Story' : 'Notre Histoire'}
            </Link>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Link as="a" href="/#home" variant="nav">
              {lang === 'en' ? 'Home' : 'Home'}
            </Link>
            <Link as="a" href="/#story" variant="nav">
              {lang === 'en' ? 'Our Story' : 'Notre Histoire'}
            </Link>
          </React.Fragment>
        )}
        <Link as={ReachLink} to="/portfolio" variant="nav">
          {lang === 'en' ? 'Portfolio' : 'Portefeuille'}
        </Link>
        <Link as={ReachLink} to="/team" variant="nav">
          {lang === 'en' ? 'Our Team' : 'Notre Équipe'}
        </Link>
        <Link as={ReachLink} to="/contact" variant="nav">
          {lang === 'en' ? 'Connect' : 'Relier'}
        </Link>
        <Link as="a" href="https://shop.principlefinewines.com/Legal/Privacy-Policy" variant="nav">
          T&C
        </Link> */}
      </Flex>
      <Flex sx={{ mt: [4, 4, 4, 5] }}>
        <a
          href={instagramLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Instagram} alt="instagram" sx={{ width: '25px' }} />
        </a>
        <a
          href={facebookLink}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ ml: '1.5rem' }}
        >
          <img src={Facebook} alt="facebook" sx={{ width: '15px' }} />
        </a>
      </Flex>
      <Flex sx={{ mt: [4, 4, 4, 5] }}>
        <small sx={{
          fontSize: '11px',
          padding: '20px',
          textAlign: 'center'
        }}>
          {lang === 'en' ? copyrightEnglish : copyrightFrench}
        </small>
      </Flex>
    </Flex>
  )
}
