import styled from 'styled-components';
import { Box } from 'theme-ui';

export const BannerForm = styled(Box)`
  .v65-widgetFormWrapper {
    width: 100% !important;

    label {
      width: 100% !important;
    }

    input[type="text"] {
      padding: 0 1em;
      width: 90%!important;
      background: none;
      border: solid 1px #ffffff;
      border-radius: 15px;
      line-height: 2em;
      color: #ffffff;
    }

    legend {
      display: none;
    }

    fieldset {
      border: none;
      display: flex;
      flex-flow: wrap;

      div {
        width: 50%;
        flex: 0 0 50%;
      }
    }

    button {
      box-sizing: border-box;
      margin: 0;
      min-width: 0;
      appearance: none;
      display: inline-block;
      text-align: center;
      line-height: inherit;
      text-decoration: none;
      font-size: inherit;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      color: white;
      background-color: var(--theme-ui-colors-primary,white);
      border: 0;
      border-radius: 4px;
      padding-left: 32px;
      padding-right: 32px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 16px;
      background: none;
      border: 1px solid;
      border-color: var(--theme-ui-colors-primary,white);
      letter-spacing: 2px;
      border-radius: 35px;
      cursor: pointer;
      font-weight: 400;
      transition: all .2s ease-in-out;
      margin-top: 16px;
      border-color: var(--theme-ui-colors-secondary,#b48d59);
      color: var(--theme-ui-colors-secondary,#b48d59);
      text-transform: uppercase;
    }
  }
`;