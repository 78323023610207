/** @jsx jsx */
import { useRef } from 'react';
import { jsx, Flex, Box, Heading, Text } from 'theme-ui';

export default ({ teamMembersData }) => {
  const bioRef1 = useRef({ expanded: false });
  const bioRef2 = useRef({ expanded: false });
  const bioRef3 = useRef({ expanded: false });
  const bioRef4 = useRef({ expanded: false });
  const bioRef5 = useRef({ expanded: false });
  // const bioRef6 = useRef({ expanded: false })
  const bioRefs = [bioRef1, bioRef2, bioRef3, bioRef4, bioRef5];

  let teamMembers = teamMembersData.slice().sort((a, b) => a.order - b.order);

  const first = teamMembers.find(member => member.name === 'Eugene Mlynczyk');
  const second = teamMembers.find(member => member.name === 'Dinesh');

  const masterMembers = [first, second];
  const otherMembers = [
    teamMembers[2],
    teamMembers[3],
    teamMembers[4],
    teamMembers[5],
  ];

  const expand = ref => {
    ref.current.style.maxHeight = ref.current.expanded
      ? 'calc(1.5rem * 5)'
      : '100%';
    ref.current.style.backgroundColor = ref.current.expanded
      ? 'transparent'
      : 'black';
    ref.current.style.webkitLineClamp = !ref.current.expanded && 'unset';

    ref.current.parentNode.parentNode.style.backgroundColor = ref.current
      .expanded
      ? 'rgba(0,0,0,.75)'
      : 'black';
    ref.current.nextSibling.textContent = ref.current.expanded
      ? 'Read more'
      : 'Read less';

    ref.current.expanded = !ref.current.expanded;
  };

  return (
    <section>
      <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
        {masterMembers.map((member, i) => {
          const { id, image, name, role, bio } = member;

          return (
            <Flex
              key={id}
              sx={{
                width: '100%',
                flexDirection: ['column', 'column', 'column', 'row'],
                '& + &': {
                  ml: [0, 0, 1],
                },
              }}
            >
              <Box sx={{ flex: 1 }}>
                <img
                  src={image}
                  alt={name}
                  sx={{
                    width: ['100%', '100%', null],
                  }}
                />
              </Box>
              <Flex
                sx={{
                  flex: 1,
                  ml: 1,
                }}
              >
                <Box
                  sx={{
                    pt: ['40px', '40px', '25px'],
                    px: '25px',
                    pb: ['40px', '40px', '25px'],
                  }}
                >
                  <Heading variant="name">{name}</Heading>

                  <Text variant="role">{role}</Text>

                  <hr sx={{ width: '85px', my: 3, mx: 0 }} />

                  <Text
                    ref={bioRefs[i]}
                    variant="bio"
                    sx={{
                      display: '-webkit-box',
                      maxHeight: [
                        null,
                        null,
                        null,
                        'calc(1.5rem * 5)',
                        'calc(1.5rem * 7)',
                        'calc(1.5rem * 10)',
                      ],
                      overflow: 'hidden',
                      transition:
                        'max-height .75s ease-in-out, background-color 1s ease-in-out',
                      '-webkit-line-clamp': [null, null, null, '5', '7', '10'],
                      '-webkit-box-orient': 'vertical',
                    }}
                  >
                    {bio}
                  </Text>

                  <button
                    onClick={() => expand(bioRefs[i])}
                    sx={{
                      display: ['none', 'none', 'none', 'block'],
                      px: 0,
                      py: 3,
                      background: 'none',
                      color: 'white',
                      border: 'none',
                      outline: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    Read more
                  </button>
                </Box>
              </Flex>
            </Flex>
          );
        })}
      </Flex>

      <Flex
        sx={{
          width: '100%',
          mt: [null, null, null, '-2px', '-2px'],
          flexDirection: ['column', 'column', 'column', 'row'],
        }}
      >
        {otherMembers.map((member, i) => {
          const { id, image, name, role, bio } = member;

          return (
            <Flex
              key={id}
              sx={{
                position: 'relative',
                flex: 1,
                flexDirection: ['column', 'column', 'row', 'column'],
                '& + &': {
                  mt: [1, 1, 1, 0],
                  ml: [0, 0, 1],
                },
                '&:hover': {
                  '.member-info': {
                    opacity: 1,
                  },
                },
              }}
            >
              <img
                src={image}
                alt={name}
                sx={{
                  width: ['100%', '100%', '450px', '100%'],
                  height: ['100%', '100%', '500px', '100%'],
                }}
              />
              <Flex
                className="member-info"
                sx={{
                  position: ['relative', 'relative', 'relative', 'absolute'],
                  flexDirection: 'column',
                  width: ['auto', 'auto', '100%'],
                  height: '100%',
                  pt: ['40px', '40px', '25px'],
                  px: [0, '25px'],
                  pb: ['40px', '40px', '25px'],
                  top: [null, null, null, '50%'],
                  left: [null, null, null, '50%'],
                  transform: [null, null, null, 'translate(-50%, -50%)'],
                  backgroundColor: ['none', 'none', 'rgba(0,0,0,.75)'],
                  transition:
                    'opacity .2s ease-in-out, background-color 1s ease-in-out',
                  opacity: [1, 1, 1, 0],
                }}
              >
                <Box sx={{ px: ['25px', 0] }}>
                  <Heading variant="name">{name}</Heading>
                  <Text variant="role">{role}</Text>
                  <hr sx={{ width: '85px', my: 3, mx: 0 }} />
                  <Text
                    ref={bioRefs[i + 2]}
                    variant="bio"
                    sx={{
                      display: '-webkit-box',
                      maxHeight: [
                        null,
                        null,
                        null,
                        'calc(1.5rem * 5)',
                        'calc(1.5rem * 7)',
                      ],
                      overflow: 'hidden',
                      transition:
                        'max-height .75s ease-in-out, background-color 1s ease-in-out',
                      '-webkit-line-clamp': [null, null, null, '5', '7'],
                      '-webkit-box-orient': 'vertical',
                    }}
                  >
                    {bio}
                  </Text>
                  <button
                    onClick={() => expand(bioRefs[i + 2])}
                    sx={{
                      display: ['none', 'none', 'none', 'block'],
                      px: 0,
                      py: 3,
                      background: 'none',
                      color: 'white',
                      border: 'none',
                      outline: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    Read more
                  </button>
                </Box>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </section>
  );
};
