import React from 'react'
import Layout from '../components/Layout'

export default () => (
  <Layout>
    <div>
      <h1>Oops, that's a 404</h1>
    </div>
  </Layout>
)
