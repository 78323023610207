/** @jsx jsx */
import { jsx, Heading, Grid, Flex, Box, Input, Select, Button } from 'theme-ui'
import React, { useState } from 'react'
import { useFormik } from 'formik'
import axios from 'axios'

export default ({ placeholders, lang }) => {
  const WEBSITE_URL = 'https://pf.steamzombie.com'    
  const FORM_ID = '1662'

  const [isSuccessMessage, setIsSuccessMessage] = useState(false)
  const [messageSent, setMessageSent] = useState(false)

  const {
    handleChange,
    isSubmitting,
    values,
    handleSubmit,
  } = useFormik({
    initialValues: {
      fullname: '',
      province: '',
      background: '',
      email: '',
      phone: '',
      message: '',
    },
    onSubmit: ({
      fullname,
      province,
      background,
      email,
      phone,
      message
    }, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      const bodyFormData = new FormData()
      bodyFormData.set('fullname', fullname)
      bodyFormData.set('your-recipient', province)
      // bodyFormData.set('background', background)
      bodyFormData.set('email', email)
      // bodyFormData.set('phone', phone)
      bodyFormData.set('message', message)

      axios({
        method: 'post',
        url: `${WEBSITE_URL}/wp-json/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`,
        data: bodyFormData
      }).then(response => {
        resetForm()
        setSubmitting(false)
        setMessageSent(true)
        setIsSuccessMessage(true)
      }).catch(error => {
        setSubmitting(false)
        setMessageSent(true)
        setIsSuccessMessage(false)
      })
    },
  })

  return (
    <Box sx={{ mx: 4, mb: 4 }}>

      {messageSent && isSuccessMessage ? (
        <Flex sx={{
          minHeight: '325px',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 4
        }}>
          {placeholders.successText}
        </Flex>
      ) : (
        <React.Fragment>
          <Heading>{lang === 'en' ? 'Connect' : 'Relier'}</Heading>

          <Grid
            as="form"
            gap={5}
            columns={[1, 1, 2]}
            onSubmit={handleSubmit}
            sx={{ py: 4 }}
          >
            <div>
              <Input
                id="fullname"
                name="fullname"
                type="text"
                onChange={handleChange}
                value={values.fullname}
                placeholder={placeholders.name}
                required
              />
            </div>

            <div>
              <Input
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                placeholder={placeholders.email}
                required
              />
            </div>

            {/* <div>
              <Input
                id="phone"
                name="phone"
                type="text"
                onChange={handleChange}
                value={values.phone}
                placeholder={placeholders.phone}
                required
              />
            </div> */}

            <div>
              <Select
                name="province"
                id="province"
                onChange={handleChange}
                value={values.province}
                placeholder={placeholders.province}
                sx={{ color: values.province === '' ? 'grey' : 'primary' }}
                required
              >
                <option value="">{placeholders.province}</option>
                <option value="Ontario">Ontario</option>
                <option value="Quebec">Quebec</option>
                <option value="Manitoba">Manitoba</option>
                <option value="Saskatchewan">Saskatchewan</option>
                <option value="British Columbia">British Columbia</option>
                <option value="Atlantic Provinces">Atlantic Provinces</option>
              </Select>
            </div>

            {/* <div>
              <Select
                name="background"
                id="background"
                onChange={handleChange}
                value={values.background}
                placeholder={placeholders.background}
                sx={{ color: values.background === '' ? 'grey' : 'primary' }}
                required
              >
                <option value="">{placeholders.background}</option>
                <option value="client">{lang === 'en' ? 'Private Client' : 'Un particulier'}</option>
                <option value="professional">{lang === 'en' ? 'Wine Trade Professional' : 'Un professionnel du commerce du vin'}</option>
              </Select>
            </div> */}

            <div>
              <Input
                id="message"
                name="message"
                type="text"
                onChange={handleChange}
                value={values.message}
                placeholder={placeholders.message}
                required
              />
            </div>

            <Flex sx={{
              justifyContent: ['center', 'center', 'flex-end'],
              gridColumnStart: [1, 1, 2]
            }}>
              <Button type="submit" value="Send Message" disabled={isSubmitting}>
                {placeholders.buttonText}
              </Button>
            </Flex>
          </Grid>
        </React.Fragment>
      )}

      {messageSent && !isSuccessMessage && (
        <Flex sx={{ justifyContent: ['center', 'flex-end'] }}>
          {placeholders.errorText}
        </Flex>
      )}
    </Box>
  )
}