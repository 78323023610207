/** @jsx jsx */
import { jsx, Container, Box, Heading, Text } from 'theme-ui'
import { useQuery, gql } from '@apollo/client'
import Layout from '../components/Layout'
import Carousel from '../components/Carousel'
import Banner from '../components/Banner'
import { useLangState } from '../context/language-context'
import Arrow from '../assets/svg/arrow.svg'

const WP_QUERY = gql`
  query MyQuery {
    pages {
      nodes {
        siteContent {
          home {
            heroImage {
              mediaItemUrl
            }
            heroTitleEnglish
            heroTitleFrench
            storyImage {
              mediaItemUrl
            }
            storyTitleEnglish
            storyTitleFrench
            storyTextEnglish
            storyTextFrench
            philosophyImage {
              mediaItemUrl
            }
            philosophyTitleEnglish
            philosophyTitleFrench
            philosophyTextEnglish
            philosophyTextFrench
          }
        }
      }
    }
    carouselWines {
      nodes {
        id
        title
        carouselFields {
          titleFrench
          descriptionEnglish
          descriptionFrench
          image {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default () => {
  const langState = useLangState()
  const lang = langState ? langState.lang : 'en'
  const { loading, data } = useQuery(WP_QUERY)

  if (loading) return null

  const {
    heroImage,
    heroTitleEnglish,
    heroTitleFrench,
    storyImage,
    storyTitleEnglish,
    storyTitleFrench,
    storyTextEnglish,
    storyTextFrench,
    philosophyImage,
    philosophyTitleEnglish,
    philosophyTitleFrench,
    philosophyTextEnglish,
    philosophyTextFrench
  } = data.pages.nodes[0].siteContent.home

  const wines = data.carouselWines.nodes

  const winesEnglish = wines.map(wine => (
    {
      id: wine.id,
      name: wine.title,
      description: wine.carouselFields.descriptionEnglish,
      image: wine.carouselFields.image.mediaItemUrl
    }
  ))

  const winesFrench = wines.map(wine => (
    {
      id: wine.id,
      name: wine.carouselFields.titleFrench,
      description: wine.carouselFields.descriptionFrench,
      image: wine.carouselFields.image.mediaItemUrl
    }
  ))

  return (
    <Layout>
      <section id="home" sx={{
        position: 'relative',
        maxHeight: '800px',
        height: '100vh',
        mt: ['-90px', '-90px', '-120px']
      }}>
        {heroImage &&
          <img src={heroImage.mediaItemUrl} alt="hero" sx={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'bottom'
          }} />
        }

        <div sx={{
          position: 'absolute',
          top: '60%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          textAlign: 'center'
        }}>
          <Heading>
            {lang === 'en' ? heroTitleEnglish : heroTitleFrench}
          </Heading>

          <img src={Arrow} alt="arrow" sx={{ mt: 6 }}/>
        </div>
      </section>

      <Box as="section" id="story" sx={{
        position: 'relative',
        maxHeight: '800px',
        height: '100vh'
       }}>

        {storyImage &&
          <img src={storyImage.mediaItemUrl} alt="story" sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            zIndex: -1
          }} />
        }

        <Box sx={{
          position: 'absolute',
          top: '50%',
          pl: [4, 4, 0],
          pr: [5, 5, 0],
          left: [null, null, '15%'],
          transform: 'translateY(-50%)',
          zIndex: 2,
        }}>
          <Heading sx={{ mb: 2 }}>
            {lang === 'en' ? storyTitleEnglish : storyTitleFrench}
          </Heading>
          <Text
            dangerouslySetInnerHTML={{__html: lang === 'en' ? storyTextEnglish : storyTextFrench}}
            sx={{ maxWidth: '600px', fontSize: ['18px', '18px', '20px'] }}
          />
        </Box>
      </Box>

      <Box as="section" sx={{
        position: 'relative',
        maxHeight: '800px',
        height: '100vh'
      }}>

        {philosophyImage &&
          <img src={philosophyImage.mediaItemUrl} alt="story" sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            zIndex: -1
          }} />
        }

        <Box sx={{
          position: 'absolute',
          top: '50%',
          pl: [4, 4, 0],
          pr: [5, 5, 0],
          right: [null, null, '15%'],
          transform: 'translateY(-50%)',
          textAlign: ['left', 'left', 'right']
        }}>
          <Heading sx={{ mb: 2 }}>
            {lang === 'en' ? philosophyTitleEnglish : philosophyTitleFrench}
          </Heading>
          <Text
            dangerouslySetInnerHTML={{__html: lang === 'en' ? philosophyTextEnglish : philosophyTextFrench}}
            sx={{ maxWidth: '600px', fontSize: ['18px', '18px', '20px'] }}
          />
        </Box>
      </Box>

      <Box as="section" sx={{ position: 'relative' }}>
        <Container>
          <Carousel carouselWines={lang === 'en' ? winesEnglish : winesFrench} lang={lang} />
        </Container>
      </Box>

      <Banner lang={lang} />
    </Layout>
  )
}
